import React from 'react'
import '../css/Features.css'
function Features() {
  return (
    <div className='features'>
        <h3>Some Features that Made us Unique</h3>
          <p>Who are in extremely love with eco friendly system.</p>
   <div className="features_container">
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
   </div>
   <div className="features_container">
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
    <div className="small_cards">
     <h5>Expert Technicians</h5>
     <p>Usage of the Internet is becoming more common due 
        to rapid advancement of technology and power.</p>
    </div>
   </div>
    </div>
  )
}

export default Features